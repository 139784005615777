import axios from "axios";
import { TicketsAndSupportActions } from "./tickets&SupportAction";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";

const getAdminURL = (state) => {
  return state.environnment.environmentLists.adminBaseURL;
};

export const getAllTicketsAsync = () => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      let { _id } = getState().auth.user;
      let { skip, limit } = getState().ticketsAndSupport;
      dispatch(TicketsAndSupportActions.getAllTicketsStart());
      let { data } = await axios({
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/getAllSupportTicket/${_id}?skip=${skip}&limit=${limit}`,
        method: "GET",
      });
      if (data.responseCode === 200) {
        return dispatch(
          TicketsAndSupportActions.getAllTicketsSuccess(data.responseData)
        );
      }
      dispatch(TicketsAndSupportActions.getAllTicketsError());
      return dispatch(
        showSuccessSnackbar("success", data.responseMessage, 3000)
      );
    } catch (error) {
      dispatch(TicketsAndSupportActions.getAllTicketsError());
      return dispatch(
        showSuccessSnackbar(
          "error",
          "Error while fetching Data.Please try again after some time",
          3000
        )
      );
    }
  };
};

export const addCommentAsync = (selectedTicketID, comment) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      let { _id } = getState().auth.user;
      dispatch(TicketsAndSupportActions.addCommentStart());
      let { data } = await axios({
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/addComment/${_id}/${selectedTicketID}`,
        method: "POST",
        data: comment,
      });
      if (data.responseCode === 200) {
        dispatch(TicketsAndSupportActions.addCommentSuccess(data.responseData));
        return dispatch(
          showSuccessSnackbar("success", data.responseMessage, 3000)
        );
      }
      dispatch(TicketsAndSupportActions.addCommentError());
      return dispatch(showSuccessSnackbar("error", data.responseMessage, 3000));
    } catch (error) {
      dispatch(TicketsAndSupportActions.addCommentError());
      return dispatch(
        showSuccessSnackbar("error", "Please try again later.", 3000)
      );
    }
  };
};

export const addFaqAsync = (faq, projectId) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      let { _id } = getState().auth.user;
      dispatch(TicketsAndSupportActions.addFaqStart());
      let { data } = await axios({
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/addFAQ/${_id}/${projectId}`,
        method: "POST",
        data: faq,
      });
      if (data.responseCode === 200) {
        dispatch(TicketsAndSupportActions.addFaqSuccess(data.responseData));
        dispatch(showSuccessSnackbar("success", data.responseMessage, 3000));
        return data;
      }
      dispatch(TicketsAndSupportActions.addFaqError());
      dispatch(showSuccessSnackbar("error", data.responseMessage, 3000));
      return data;
    } catch (error) {
      dispatch(TicketsAndSupportActions.addFaqError());
      return dispatch(
        showSuccessSnackbar("error", "Please try again later.", 3000)
      );
    }
  };
};

export const changeTicketStatusAsync = (ticketId, ticketStatus) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      let { _id } = getState().auth.user;
      dispatch(TicketsAndSupportActions.changeTicketStatusStart());
      let { data } = await axios({
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/changeTicketStatus/${_id}/${ticketId}/${ticketStatus}`,
        method: "GET",
      });
      if (data.responseCode === 200) {
        dispatch(
          TicketsAndSupportActions.changeTicketStatusSuccess(data.responseData)
        );
        return dispatch(
          showSuccessSnackbar("success", data.responseMessage, 3000)
        );
      }
      dispatch(TicketsAndSupportActions.changeTicketStatusError());
      return dispatch(showSuccessSnackbar("error", data.responseMessage, 3000));
    } catch (error) {
      dispatch(TicketsAndSupportActions.changeTicketStatusError());
      return dispatch(
        showSuccessSnackbar(
          "error",
          "Error while updating ticket status. Please try again later.",
          3000
        )
      );
    }
  };
};

export const updateTicketDetailsAsync = (ticketId, details) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      let { _id } = getState().auth.user;
      dispatch(TicketsAndSupportActions.updateTicketsDetailsStart());
      let { data } = await axios({
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/updateTicketDetails/${_id}/${ticketId}`,
        method: "PUT",
        data: details,
      });
      if (data.responseCode === 200) {
        dispatch(
          TicketsAndSupportActions.updateTicketsDetailsSuccess(
            data.responseData
          )
        );
        return dispatch(
          showSuccessSnackbar("success", data.responseMessage, 3000)
        );
      }
      dispatch(TicketsAndSupportActions.updateTicketsDetailsError());
      return dispatch(showSuccessSnackbar("error", data.responseMessage, 3000));
    } catch (error) {
      dispatch(TicketsAndSupportActions.updateTicketsDetailsError());
      return dispatch(
        showSuccessSnackbar(
          "error",
          "Error while updating ticket details. Please try again later.",
          3000
        )
      );
    }
  };
};
