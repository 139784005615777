import React, { useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { getEnvironmentsAsync } from "../app/modules/GetEnvironment/getEnvironmentApi";
import { Layout, LayoutSplashScreen } from "../_metronic/layout";

export function Routes() {
  const dispatch = useDispatch();

  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.authToken && auth.user,
      // tokenVerified: auth.tokenVerified,
    }),
    shallowEqual
  );
  const { envLoading } = useSelector((state) => state.environnment);

  // useEffect(() => {
  //     if (!tokenVerified) {
  //         dispatch(verifyAuthTokenAsync());
  //     }
  // }, [tokenVerified])

  useEffect(() => {
    dispatch(getEnvironmentsAsync());
  }, []);

  if (envLoading) {
    return <LayoutSplashScreen />;
  }

  // if (!tokenVerified) {
  //     return <LayoutSplashScreen />
  // }

  return (
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
