import axios from "axios";
import { ProjectManagementActions } from "./projectManagementAction";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";

const getAdminURL = (state) => {
  return state.environnment.environmentLists.adminBaseURL;
};

export const getAllProjectDetailsAsync = (
  searchBy,
  searchText,
  searchStatus,
  dir
) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      let { _id } = getState().auth.user;
      let { skip, limit } = getState().projectManagement;
      dispatch(ProjectManagementActions.getAllProjectDetailsStart());
      const { data } = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/getAllProjects/${_id}?skip=${skip}&limit=${limit}&column=${searchBy}&dir=${dir}&search=${searchText}&adminVerification=${searchStatus}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.responseCode === 200) {
        return dispatch(
          ProjectManagementActions.getProjectDetailsSuccess(data.responseData)
        );
      }
      dispatch(ProjectManagementActions.getProjectDetailsError());
      return dispatch(
        showSuccessSnackbar("success", data.responseMessage, 3000)
      );
    } catch (error) {
      dispatch(ProjectManagementActions.getProjectDetailsError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const updateProjectDetailsAsync = ({ adminVerification, id }) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      let { _id } = getState().auth.user;

      dispatch(ProjectManagementActions.updateProjectDetailsStart());
      const { data } = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/updateProjectVerification/${_id}/${id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: { adminVerification },
      });
      if (data.responseCode === 200) {
        dispatch(
          ProjectManagementActions.updateProjectDetailsSuccess(
            data.responseData
          )
        );
        return dispatch(
          showSuccessSnackbar("success", data.responseMessage, 3000)
        );
      }
      dispatch(ProjectManagementActions.updateProjectDetailsError());
      return dispatch(showSuccessSnackbar("error", data.responseMessage, 3000));
    } catch (error) {
      dispatch(ProjectManagementActions.updateProjectDetailsError(error));
      return dispatch(
        showSuccessSnackbar("error", "Error while fetching data", 3000)
      );
    }
  };
};

export const getProjectAsync = (projectId) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      let { _id } = getState().auth.user;
      dispatch(ProjectManagementActions.getProjectStart());
      const { data } = await axios({
        method: "Get",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/getProjectDetails/${_id}/${projectId}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.responseCode === 200) {
        return dispatch(
          ProjectManagementActions.getProjectSuccess(data.responseData)
        );
      }
      return dispatch(ProjectManagementActions.getProjectError());
    } catch (error) {
      dispatch(ProjectManagementActions.getProjectError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const getProjectFaqAsync = (projectId) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      let { _id } = getState().auth.user;
      let { faqSkip, faqLimit } = getState().projectManagement;
      dispatch(ProjectManagementActions.getProjectFaqStart());
      const { data } = await axios({
        method: "Get",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/getAllFAQs/${_id}/${projectId}?skip=${faqSkip}&limit=${faqLimit}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.responseCode === 200) {
        return dispatch(
          ProjectManagementActions.getProjectFaqSuccess(data.responseData)
        );
      }
      return dispatch(ProjectManagementActions.getProjectFaqError());
    } catch (error) {
      dispatch(ProjectManagementActions.getProjectFaqError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const updateProjectFaqAsync = (values, projectId, faqId) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      let { _id } = getState().auth.user;
      dispatch(ProjectManagementActions.updateProjectFaqStart());
      const { data } = await axios({
        method: "PUT",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/updateFAQ/${_id}/${projectId}/${faqId}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: values,
      });
      if (data.responseCode === 200) {
        return dispatch(
          ProjectManagementActions.updateProjectFaqSuccess(data.responseData)
        );
      }
      return dispatch(ProjectManagementActions.updateProjectFaqError());
    } catch (error) {
      dispatch(ProjectManagementActions.updateProjectFaqError());
      dispatch(showSuccessSnackbar("error", "Error while updating data", 3000));
    }
  };
};

export const deleteProjectFaqAsync = (projectId, faqId) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      let { _id } = getState().auth.user;
      dispatch(ProjectManagementActions.deleteProjectFaqStart());
      const { data } = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/deleteFAQ/${_id}/${projectId}/${faqId}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.responseCode === 200) {
        return dispatch(
          ProjectManagementActions.deleteProjectFaqSuccess(data.responseData)
        );
      }
      return dispatch(ProjectManagementActions.deleteProjectFaqError());
    } catch (error) {
      dispatch(ProjectManagementActions.deleteProjectFaqError());
      dispatch(showSuccessSnackbar("error", "Error while deleting Faq", 3000));
    }
  };
};
