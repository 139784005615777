import { ProjectManagementMap } from "./projectManagementAction";

const initialState = {
  isLoading: false,
  projectDetails: {},
  Project: {},
  refreshFaqList: true,
  refreshprojectDetailsList: true,
  searchBy: "",
  faqList: {},
  searchText: "",
  searchStatus: "",
  skip: 0,
  faqSkip: 0,
  faqLimit: 10,
  column: "",
  dir: "",
  limit: 10,
  selectedFaq: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ProjectManagementMap.GET_ALL_PROJECT_DETAIL_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ProjectManagementMap.GET_ALL_PROJECT_DETAIL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        projectDetails: action.payload,
        refreshprojectDetailsList: false,
      };
    }
    case ProjectManagementMap.GET_ALL_PROJECT_DETAIL_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshprojectDetailsList: false,
      };
    }
    case ProjectManagementMap.UPDATE_PROJECT_DETAIL_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case ProjectManagementMap.UPDATE_PROJECT_DETAIL_SUCCESS: {
      return {
        ...state,
        refreshprojectDetailsList: true,
      };
    }
    case ProjectManagementMap.UPDATE_PROJECT_DETAIL_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshprojectDetailsList: false,
      };
    }
    case ProjectManagementMap.SEARCH_TEXT_CHANGE: {
      return {
        ...state,
        searchText: action.payload,
      };
    }
    case ProjectManagementMap.SEARCH_STATUS_CHANGE: {
      return {
        ...state,
        searchStatus: action.payload,
        searchBy: "",
        skip: 0,
        refreshprojectDetailsList: true,
      };
    }
    case ProjectManagementMap.SET_PROJECT_DETAIL_BATCH_NUMBER: {
      return {
        ...state,
        skip: action.payload,
        refreshprojectDetailsList: true,
      };
    }
    case ProjectManagementMap.REFRESH_PROJECT_DETAILS_LIST: {
      return {
        ...state,
        skip: 0,
        refreshprojectDetailsList: true,
      };
    }

    case ProjectManagementMap.GET_PROJECT_START: {
      return {
        ...state,
        isProjectLoading: true,
      };
    }
    case ProjectManagementMap.GET_PROJECT_SUCCESS: {
      return {
        ...state,
        isProjectLoading: false,
        Project: action.payload,
      };
    }
    case ProjectManagementMap.GET_PROJECT_ERROR: {
      return {
        ...state,
        isProjectLoading: false,
        Project: {},
      };
    }
    case ProjectManagementMap.SET_SORT_CHANGE: {
      return {
        ...state,
        searchBy: action.payload?.column,
        dir: action.payload?.dir,
        refreshprojectDetailsList: true,
      };
    }

    case ProjectManagementMap.GET_PROJECT_FAQ_START: {
      return {
        ...state,
        isFaqLoading: true,
      };
    }
    case ProjectManagementMap.GET_PROJECT_FAQ_SUCCESS: {
      return {
        ...state,
        refreshFaqList: false,
        isFaqLoading: false,
        faqList: action.payload,
      };
    }
    case ProjectManagementMap.GET_PROJECT_FAQ_ERROR: {
      return {
        ...state,
        refreshFaqList: false,
        isFaqLoading: false,
        faqList: {},
      };
    }
    case ProjectManagementMap.SET_PROJECT_FAQ_BATCH_NUMBER: {
      return {
        ...state,
        faqSkip: action.payload,
        refreshFaqList: true,
      };
    }
    case ProjectManagementMap.SET_SELECTED_FAQ: {
      return {
        ...state,
        selectedFaq: action.payload,
      };
    }

    case ProjectManagementMap.UPDATE_PROJECT_FAQ_START: {
      return {
        ...state,
        isFaqLoading: true,
      };
    }
    case ProjectManagementMap.UPDATE_PROJECT_FAQ_SUCCESS: {
      return {
        ...state,
        isFaqLoading: false,
        refreshFaqList: true,
      };
    }
    case ProjectManagementMap.UPDATE_PROJECT_FAQ_ERROR: {
      return {
        ...state,
        isFaqLoading: false,
        refreshFaqList: false,
      };
    }
    case ProjectManagementMap.DELETE_FAQ_START: {
      return {
        ...state,
        isFaqLoading: true,
      };
    }
    case ProjectManagementMap.DELETE_FAQ_SUCCESS: {
      return {
        ...state,
        isFaqLoading: false,
        refreshFaqList: true,
      };
    }
    case ProjectManagementMap.DELETE_FAQ_ERROR: {
      return {
        ...state,
        isFaqLoading: false,
        refreshFaqList: false,
      };
    }
    default:
      return { ...state };
  }
};
