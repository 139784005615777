import React, { useEffect, useRef, useState } from "react";
import ProjectManagementTable from "./projectManagementTable";
import Select from "react-select";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
  Card,
  CardHeader,
  CardHeaderToolbar,
} from "./../../../../_metronic/_partials/controls";
import { ProjectManagementActions } from "../redux";
import { getAllProjectDetailsAsync } from "../redux";

export const ProjectManagement = () => {
  const dispatch = useDispatch();
  const searchProjectRef = useRef();
  const {
    refreshprojectDetailsList,
    searchBy,
    dir,
    searchStatus,
    searchText,
  } = useSelector((state) => state.projectManagement, shallowEqual);
  const state = useSelector((state) => state, shallowEqual);

  useEffect(() => {
    if (searchProjectRef.current) {
      let keyPressEvent = (e) => {
        if (e.keyCode === 13) {
          e.preventDefault();
          dispatch(ProjectManagementActions.refreshProjectList());
        }
      };
      let input = searchProjectRef.current;
      input.addEventListener("keyup", keyPressEvent);

      return () => {
        input.removeEventListener("keyup", keyPressEvent);
        dispatch(ProjectManagementActions.searchTextChange(""));
        input.value = "";
      };
    }
  }, []);
  const [dropDownValue, setDropDownValue] = useState({
    value: "",
    label: "All",
  });
  const onSearchTextChange = (e) => {
    dispatch(ProjectManagementActions.searchTextChange(e.target.value));
  };
  const handleChange = (e) => {
    setDropDownValue(e);
    dispatch(ProjectManagementActions.searchStatusChange(e.value));
  };
  useEffect(() => {
    if (refreshprojectDetailsList) {
      dispatch(
        getAllProjectDetailsAsync(searchBy, searchText, searchStatus, dir)
      );
    }
  }, [refreshprojectDetailsList]);

  const dropdownOption = [
    { value: "", label: "All" },
    { value: "ACCEPTED", label: "Accepted" },
    { value: "REJECTED", label: "Rejected" },
    { value: "PENDING", label: "Pending" },
  ];
  return (
    <>
      <Card>
        <CardHeader title="Project Management">
          <CardHeaderToolbar>
            <div className="d-flex flex-wrap ap_filter_wraper justify-content-end align-items-center">
              <form
                className="subheader_filter"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="form-row">
                  <div className="form-group mr-3 mb-1">
                    <Select
                      value={dropDownValue}
                      placeholder="Select Template"
                      className={`form-control border-0 p-0 w-150px `}
                      classNamePrefix="phoSelect"
                      options={dropdownOption}
                      onChange={(e) => handleChange(e)}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "#DCF4E4",
                          primary75: "#DCF4E4",
                          primary50: "#DCF4E4",
                          primary: "#50C878",
                        },
                      })}
                    />
                    {/* <select
                      className="form-control"
                      name="status"
                      onChange={handleChange}
                      placeholder="Filter by Status"
                    >
                      <option value="">All</option>
                      <option value="ACCEPTED">Accepted</option>
                      <option value="REJECTED">Rejected</option>
                      <option value="PENDING">Pending</option>
                    </select> */}
                  </div>
                  <div className="form-group mr-3 mb-1">
                    <input
                      name="Search"
                      placeholder="Search. . ."
                      type="text"
                      className="form-control"
                      value={searchText}
                      onChange={onSearchTextChange}
                      ref={searchProjectRef}
                    />
                  </div>
                </div>
              </form>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        <ProjectManagementTable />
      </Card>
    </>
  );
};
