import React, { useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Table, Modal } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import {
  ProjectManagementActions,
  updateProjectDetailsAsync,
  getProjectAsync,
} from "../redux";
import BasicPagination from "../../pagination/BasicPagination";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

const ProjectManagementTable = () => {
  const {
    isLoading,
    projectDetails,
    skip,
    limit,
    dir,
    isProjectLoading,
    searchBy,
  } = useSelector((state) => state.projectManagement, shallowEqual);
  const [selectedProject, setSelectedProject] = useState({});
  const dispatch = useDispatch();
  const [DetailsModal, setDetailsModal] = useState(false);
  const DetailsClose = () => setDetailsModal(false);
  const DetailsShow = (projectDetail) => {
    dispatch(getProjectAsync(projectDetail._id));
    setSelectedProject(projectDetail);
    setDetailsModal(true);
  };

  const getClassName = (status) => {
    switch (status) {
      case "PENDING":
        return "info";
      case "ACCEPTED":
        return "success";
      case "REJECTED":
        return "danger";
      default:
        return "";
    }
  };

  const getStatusClassName = (status) => {
    switch (status) {
      case "OPEN": {
        return "lbl-grey";
      }
      case "COMPLETED": {
        return "lbl-green";
      }
      case "DELIVERED": {
        return "lbl-blue";
      }
      case "IN PROGRESS": {
        return "lbl-yellow";
      }
      default:
        return "lbl-yellow";
    }
  };

  const onPageChange = (currentBatch) => {
    let count = currentBatch ? currentBatch - 1 : skip;
    dispatch(ProjectManagementActions.setProjectDetailBatchNumber(count));
  };
  const updateProjectStatus = (projectDetail, status) => {
    const data = {
      adminVerification: status,
      id: projectDetail._id,
    };
    DetailsClose();
    dispatch(updateProjectDetailsAsync(data));
  };
  const handleSort = (column, dir) => {
    dispatch(ProjectManagementActions.setSort({ column, dir }));
  };
  return (
    <div className="px-6 pt-0 dash_wt_bx pb-0 ovflhddn loader-display-position">
      <div className="tblmrgn mt-0">
        <div className="mt-5">
          <div>
            <Table
              hover
              responsive
              className="mb-0 default_table with_hoverbtn mrpdng_tbl"
            >
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th
                    onClick={() =>
                      handleSort(
                        "title",
                        dir === "" || dir === "dsc" ? "asc" : "dsc"
                      )
                    }
                  >
                    Project Name{" "}
                    {dir !== "" && searchBy === "title" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "dsc" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th>
                  {/* <th
                    onClick={() =>
                      handleSort(
                        "category",
                        dir === "" || dir === "dsc" ? "asc" : "dsc"
                      )
                    }
                  >
                    Category{" "}
                    {dir !== "" && searchBy === "category" && (
                      <span className="svg-icon svg-icon-sm svg-icon-primary ml-1">
                        {dir === "dsc" ? (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Down-2.svg"
                            )}
                          />
                        ) : (
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Up-2.svg"
                            )}
                          />
                        )}
                      </span>
                    )}
                  </th> */}
                  <th>Initiator </th>
                  <th>cost</th>
                  <th>verified</th>
                  <th>Status</th>
                  <th width="180px">Action</th>
                </tr>
              </thead>
              <tbody>
                {projectDetails.records && projectDetails.records.length ? (
                  projectDetails.records.map((projectDetail, index) => (
                    <tr key={projectDetail._id}>
                      <td>
                        <span className="f-w-500">
                          {skip * limit + (index + 1)}
                        </span>
                      </td>
                      <td>
                        <span className="f-w-500">{projectDetail.title}</span>
                      </td>
                      {/* <td>
                        <span className="f-w-500">
                          {projectDetail.category}{" "}
                        </span>
                      </td> */}
                      <td>
                        <span className="f-w-500">
                          {projectDetail.initiatorDetails
                            ? projectDetail.initiatorDetails.fullName
                            : ""}
                        </span>
                      </td>
                      <td>
                        <span className="f-w-500">
                          ${projectDetail.totalBudget}
                        </span>
                      </td>
                      <td>
                        <span className="f-w-500">
                          <span
                            className={`label label-lg label-light-${getClassName(
                              projectDetail.adminVerification
                            )} label-inline`}
                          >
                            {projectDetail.adminVerification}
                          </span>
                        </span>
                      </td>
                      <td>
                        {/* <span
                          className={`label label-lg label-light-success label-inline`}
                        >
                          {projectDetail.projectStatus}
                        </span> */}
                        <label
                          className={getStatusClassName(
                            projectDetail.projectStatus
                          )}
                        >
                          {projectDetail.projectStatus}
                        </label>
                      </td>
                      <td>
                        <a
                          title="Edit"
                          className="btn btn-icon btn-light btn-hover-success btn-sm mr-3"
                          onClick={() => DetailsShow(projectDetail)}
                        >
                          <span className="svg-icon svg-icon-md svg-icon-success">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/custom/eye.svg"
                              )}
                            />
                          </span>
                        </a>
                        <Link
                          to={`/faq-management?projectId=${projectDetail._id ? projectDetail._id : null
                            }`}
                          className="btn btn-light-success"
                        >
                          View FAQs
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8} className="text-center">
                      No Record Found
                    </td>
                  </tr>
                )}
                {isLoading && (
                  <tr>
                    <td colSpan={8} className="text-center">
                      <div className="basic-verification-loader text-center">
                        <CircularProgress />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          {!isLoading && (
            <div className="row d-flex align-items-center mr-5 ml-5 mt-4">
              <div className="col-md-8 text-right mb-5 aspgntn">
                <BasicPagination
                  totalRecords={projectDetails.recordsTotal}
                  limit={limit}
                  batch={skip + 1}
                  onBatchChange={onPageChange}
                />
              </div>
              <div className="col-md-4 mb-5 fn_s_16 f-w-400 text-right">
                Displaying {skip * limit + 1} - {skip * limit + limit} of{" "}
                {projectDetails.recordsTotal} Records
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal
        show={DetailsModal}
        onHide={DetailsClose}
        centered
        size="lg"
        className="def_modal show_close_button"
      >
        <Modal.Header closeButton>
          <Modal.Title className="fn_s_18 color_blk f-w-700">
            Project Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form def_form frmwtpddng">
            <div className="symbol symbol-60 symbol-xxl-100 align-self-start align-self-xxl-center">
              <div
                className="symbol-label p-4 d-flex align-items-center justify-content-center"
              // style={{
              //   backgroundImage: `url(${selectedProject?.logo})`,
              // }}
              >
                <img
                  src={selectedProject?.logo}
                  alt="logo"
                  className="w-100 mw-100 mh-100"
                />{" "}
              </div>
            </div>
            <p className="fn_s_18 f-w-400 mb-3">
              <span className="text-muted">Project name:</span>{" "}
              {selectedProject?.title}
            </p>
            {/* <p className="fn_s_18 f-w-400 mb-3">
              <span className="text-muted">Category:</span>{" "}
              {selectedProject?.category}
            </p> */}
            <p className="fn_s_18 f-w-400 mb-3">
              <span className="text-muted">Description:</span>{" "}
              {selectedProject?.description}
            </p>
            <p className="fn_s_18 f-w-400 mb-3">
              <span className="text-muted">Project cost:</span> $
              {selectedProject?.totalBudget}
            </p>
            {/* <p className="fn_s_18 f-w-400 mb-3">
              <span className="text-muted">Bonus:</span> $
              {selectedProject?.bonus}
            </p> */}
            <p className="fn_s_18 f-w-400 mb-3">
              <span className="text-muted">Initiator:</span>{" "}
              {selectedProject.initiatorDetails?.fullName}
            </p>
            <p className="fn_s_18 f-w-400 mb-3">
              <span className="text-muted">Verified:</span>{" "}
              {selectedProject?.adminVerification}
            </p>
            <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0">
              {(selectedProject?.adminVerification === "ACCEPTED" ||
                selectedProject?.adminVerification === "PENDING") && (
                  <div className="form-group mr-5 mb-1 mt-2">
                    <button
                      className="btn_outline_new btn-sm"
                      onClick={() =>
                        updateProjectStatus(selectedProject, "REJECTED")
                      }
                      type="button"
                    >
                      Reject
                    </button>
                  </div>
                )}
              {(selectedProject?.adminVerification === "REJECTED" ||
                selectedProject?.adminVerification === "PENDING") && (
                  <div className="form-group mr-5 mb-1 mt-2">
                    <button
                      className="btn_new btn-sm"
                      onClick={() =>
                        updateProjectStatus(selectedProject, "ACCEPTED")
                      }
                      type="button"
                    >
                      Accept
                    </button>
                  </div>
                )}
            </div>
            {isProjectLoading && (
              <div colSpan={8} className="text-center">
                <div className="basic-verification-loader text-center">
                  <CircularProgress />
                </div>
              </div>
            )}
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ProjectManagementTable;
